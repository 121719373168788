import Vuex from 'vuex'
import Vue from 'vue'
import { SearchApiRequest } from '@/store/search/searchApiRequestHelper'
import Notifications from '@/store/notifications/notifications'
import i18n from '@/libs/i18n'
import { AppV3Labels } from '@/store/appV3/moduleAppV3LabelsDef'

Vue.use(Vuex)

interface Validator {
}

interface Validators {
  [key: string]: Validator
}

const VALIDATORS: Validators = {
}

interface setLabelPayload {
  path: string
  value: any,
  labels: object | null
}

interface ModuleAppV3LabelsState{
  isLoaded: boolean
  labels: AppV3Labels | null
}

export default new Vuex.Store({
  state: (): ModuleAppV3LabelsState => (
    {
      isLoaded: false,
      labels: null,
    }
  ),
  mutations: {
    setLabels(state: ModuleAppV3LabelsState, labels: AppV3Labels) {
      state.labels = labels
      state.isLoaded = true
    },
  },
  actions: {
    loadLabels(context: any, searchId: string) {
      SearchApiRequest.getAppV3Labels(searchId).then(results => {
        context.commit('setLabels', results.data)
      }).catch(error => {
        Notifications.commit('addError', error, { root: true })
      })
    },
    saveLabels(context: any, searchId: string) {
      SearchApiRequest.setAppV3Labels(searchId, context.state.labels).then(() => {
        Notifications.commit('addPopupInfo', {
          name: i18n.t('appV3Labels.saveSuccess'),
          message: i18n.t('appV3Labels.saveSuccess'),
        }, { root: true })
      }).catch(error => {
        Notifications.commit('addError', error, { root: true })
      })
    },
    setLabelValue(context: any, configPayload: setLabelPayload) {
      const pathElements = configPayload.path.split('.')
      const firstPathElement = pathElements.shift()
      const labelsObject = configPayload.labels ?? context.state.labels
      if (!Object.keys(labelsObject).includes(firstPathElement as string)) {
        return
      }
      if (!pathElements.length) {
        labelsObject[firstPathElement as string] = configPayload.value
        return
      }
      context.dispatch('setLabelValue', {
        path: pathElements.join('.'),
        value: configPayload.value,
        labels: labelsObject[firstPathElement as string],
      })
    },
  },
  getters: {
    isLoaded(state: ModuleAppV3LabelsState) {
      return state.isLoaded
    },
    getLabels(state: ModuleAppV3LabelsState) {
      return state.labels
    },
    getFieldRules: () => (configKey: string) => {
      let rules = { required: true }
      if (configKey in VALIDATORS) {
        const { ...fieldRules } = VALIDATORS[configKey as keyof typeof VALIDATORS]
        rules = Object.assign(rules, fieldRules)
      }
      return rules
    },
  },
})
