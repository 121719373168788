<template>
  <vs-row>
    <vs-col vs-w="4" vs-type="flex" vs-justify="left" vs-align="center" class="mt-1 mb-1">
      {{ $t('appV3Labels.fields.' + labelKey) }}:
    </vs-col>
    <vs-col vs-w="8" vs-type="flex" vs-justify="left" vs-align="center">
      <ValidationProvider :rules="getFieldRules()" tag="div" class="max-width">
        <template v-slot="{ errors }">
          <vs-input
            v-model="labelValue"
            class="max-width"
          />
          <div>
            <span class="error">{{ errors[0] }}</span>
          </div>
        </template>
      </ValidationProvider>
    </vs-col>
  </vs-row>
</template>
<script>
import Vue from 'vue'
import moduleAppV3Labels from '@/store/appV3/moduleAppV3Labels'

export default Vue.extend({
  name: 'AppV3LabelsItem',
  props: ['label', 'labelKey'],
  emits: ['changeLabel'],
  computed: {
    labelValue: {
      get() {
        return this.label
      },
      set(newValue) {
        this.update(newValue)
      },
    },
  },
  methods: {
    getFieldRules() {
      return moduleAppV3Labels.getters.getFieldRules(this.labelKey)
    },
    update(value) {
      this.$emit('changeLabel', this.labelKey, value)
    },
  },
})
</script>

<style scoped>
.max-width {
  width: 100%;
}

.error {
  color: red;
}
</style>
