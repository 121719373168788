<template>
  <div ref="container">
    <ValidationObserver ref="observer">
      <b-card :title="$t('appV3Labels.header')">
        <vs-tabs v-if="isLoaded">
          <vs-tab :label="$t('appV3Labels.autocomplete')">
            <div
              v-for="(value, name, index) in getSectionKeys(getLabels.autocomplete, 'autocomplete')"
              :key="index"
            >
              <AppV3LabelsItem
                :label="value"
                :label-key="name"
                @changeLabel="onChange"
              />
            </div>
          </vs-tab>
          <vs-tab :label="$t('appV3Labels.popularQueries')">
            <div
              v-for="(value, name, index) in getSectionKeys(getLabels.popularQueries, 'popularQueries')"
              :key="index"
            >
              <AppV3LabelsItem
                :label="value"
                :label-key="name"
                @changeLabel="onChange"
              />
            </div>
          </vs-tab>
          <vs-tab :label="$t('appV3Labels.popularProducts')">
            <div
              v-for="(value, name, index) in getSectionKeys(getLabels.popularProducts, 'popularProducts')"
              :key="index"
            >
              <AppV3LabelsItem
                :label="value"
                :label-key="name"
                @changeLabel="onChange"
              />
            </div>
          </vs-tab>
          <vs-tab :label="$t('appV3Labels.serp')">
            <div
              v-for="(value, name, index) in getSectionKeys(getLabels.serp, 'serp')"
              :key="index"
            >
              <AppV3LabelsItem
                :label="value"
                :label-key="name"
                @changeLabel="onChange"
              />
            </div>
          </vs-tab>
          <vs-tab :label="$t('appV3Labels.filters')">
            <div
              v-for="(value, name, index) in getSectionKeys(getLabels.filters, 'filters')"
              :key="index"
            >
              <AppV3LabelsItem
                :label="value"
                :label-key="name"
                @changeLabel="onChange"
              />
            </div>
          </vs-tab>
          <vs-tab :label="$t('appV3Labels.pagination')">
            <div
              v-for="(value, name, index) in getSectionKeys(getLabels.pagination, 'pagination')"
              :key="index"
            >
              <AppV3LabelsItem
                :label="value"
                :label-key="name"
                @changeLabel="onChange"
              />
            </div>
          </vs-tab>
        </vs-tabs>
        <vs-row v-if="isLoaded" class="mt-2">
          <vs-col vs-lg="9" vs-sm="3" vs-xs="0" />
          <vs-col vs-lg="3" vs-sm="9" vs-xs="12">
            <vs-button class="w-100" @click="save()">
              {{ $t('appV3Labels.save') }}
            </vs-button>
          </vs-col>
        </vs-row>
      </b-card>
    </ValidationObserver>
  </div>
</template>

<script>
import Vue from 'vue'
import SearchInstanceStore from '@/store/search/searchInstance'
import AppV3LabelsItem from '@/views/components/appV3/AppV3LabelsItem.vue'
import moduleAppV3Labels from '@/store/appV3/moduleAppV3Labels'

export default Vue.extend({
  name: 'AppV3Labels',
  components: { AppV3LabelsItem },
  computed: {
    isLoaded() {
      return moduleAppV3Labels.getters.isLoaded
    },
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
    getLabels() {
      return moduleAppV3Labels.getters.getLabels
    },
  },
  watch: {
    getSearchId() {
      this.getData()
    },
  },
  mounted() {
    if (this.getSearchId !== '') {
      this.getData()
    }
  },
  methods: {
    getSectionKeys(sectionData, subsection) {
      let sectionKeys = {}
      Object.keys(sectionData).forEach(index => {
        const subsectionKey = [subsection, index].join('.')
        if (typeof sectionData[index] === 'object') {
          sectionKeys = Object.assign(
            sectionKeys,
            this.getSectionKeys(
              sectionData[index],
              subsectionKey,
            ),
          )
          return
        }
        sectionKeys[subsectionKey] = sectionData[index]
      })
      return sectionKeys
    },
    onChange(key, label) {
      moduleAppV3Labels.dispatch(
        'setLabelValue',
        {
          path: key,
          value: label,
          config: null,
        },
      )
    },
    getData() {
      moduleAppV3Labels.dispatch('loadLabels', this.getSearchId)
    },
    save() {
      return this.$refs.observer.validate()
        .then(validationResult => {
          if (validationResult) {
            moduleAppV3Labels.dispatch('saveLabels', this.getSearchId)
            return true
          }
          return false
        })
    },
  },
})
</script>
